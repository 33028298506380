@import './excr-variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Montserrat&display=swap');

:root {
  --excr-roboto-font: 'Roboto', sans-serif;
  --excr-montserrat-font: 'Montserrat', sans-serif;
}

.excr {
  margin: 0;
  background-color: var(--excr-white);
  box-sizing: border-box;

  // Fonts
  font-family: var(--excr-roboto-font), system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: var(--excr-fs-p);
  color: var(--excr-white);

  * {
    box-sizing: inherit;
  }

  section {
    padding: var(--excr-sct-vert-margin) var(--excr-sct-horiz-margin);
    display: flex;
    color: var(--excr-black);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--excr-montserrat-font);
    margin: 0;
    font-weight: 900;
  }

  h1 {
    font-size: var(--excr-fs-h1);
  }

  h2 {
    font-size: var(--excr-fs-h2);
    line-height: 1.2em;
    letter-spacing: 0.03em;
  }

  h3 {
    font-size: var(--excr-fs-h3);
    letter-spacing: 0.03em;
  }

  h4 {
    font-size: var(--excr-fs-h4);
    line-height: 1.2em;
    letter-spacing: 0.03em;
  }

  h5 {
    font-size: var(--excr-fs-h5);
    line-height: auto;
    letter-spacing: 0.08em;
  }

  h6 {
    font-size: var(--excr-fs-h6);
    letter-spacing: 0.07em;
    line-height: auto;
  }

  a {
    transition: all ease-in-out 0.2s;
  }

  a:hover {
    color: var(--excr-primary);
    transition: all ease-in-out 0.2s;
  }

  p {
    line-height: 1.3em;
    letter-spacing: 0.01em;

    &:first-of-type {
      margin-top: 0px;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  small {
    font-size: var(--excr-fs-sm);
    line-height: 1.3em;
    letter-spacing: 0.01em;
  }

  a {
    text-decoration: none;
    color: var(--excr-black);
  }

  img {
    border-style: none;
  }

  .container {
    width: 100%;
    max-width: var(--excr-large-content-width);
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }

  // Display
  .d-none {
    display: none !important;
  }

  .d-block {
    display: block !important;
  }

  .d-flex {
    display: flex !important;
  }

  // Colors
  .color-primary {
    color: var(--excr-primary) !important;
  }
  .color-accent {
    color: var(--excr-accent) !important;
  }

  .color-dark {
    color: var(--excr-dark) !important;
  }

  .color-accent-dark {
    color: var(--excr-accent-dark) !important;
  }

  // For menu OnScroll
  .bg-dark {
    background: var(--excr-dark);
  }

  .noscroll {
    overflow: hidden;
  }
}
