

html {
  min-height: 100%;
  webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

body {
  min-height: 100%;
}

.right-scroll {
  overflow: scroll;
}

.topbar {
  /* position: fixed; */
}


.step-container {
  display: flex;
}

.auth-holder .right h3 {
  font-family: "title";
  color: white;
  font-size: 30px;
  margin-top: 10px;
  text-transform: uppercase;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
}

.auth-holder .registration-cont {
  width: 100%;
  max-width: 680px;
  min-height: 600px;
  height: auto;
  overflow: visible;
  max-height: 80vh;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0px 20px 20px 0px rgba(7, 105, 195, 0.39);
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.auth-holder .register-button {
  position: relative;
}

#labeledCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 13px;
  margin-bottom: 0px;
  font-weight: 700;
  width: 100%;
}

#over18checkboxInput {
  display: none;
}
#privacyCheckboxInput {
    display: none;
}
#termsCheckboxInput {
    display: none;
}

.checkbox-active {
    width: 25px;
    height: 25px;
    margin-left: 7.5px;
    margin-top: 7.5px;
    border-radius: 5px;
    background-color: #ffbe08;
    float: left;
}

.errorMessage {
  color: #ffbe08;
  text-align: center;
}

.register-cont button:disabled {
    background-color: grey;
}

.swal2-title {
  font-weight: 500;
}

.panel .title-container h4 {
  letter-spacing: 1px;
}

select {
    width: 100%;
    float: left;
    margin-bottom: 20px;
    height: 50px;
    border: none;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.13);
    padding-left: 15px;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.2px;
}