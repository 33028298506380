.contactCTA {
  padding: 0 !important;
  display: flex;
  flex-direction: column;

  &.grey {
    background-color: var(--excr-grey);
  }

  @media screen and (min-width: 900px) {
    flex-direction: row !important;

    min-height: 400px;
  }

  @media screen and (min-width: 1200px) {
    min-height: 640px;
  }

  &__img {
    min-height: 270px;
    position: relative;

    @media screen and (min-width: 900px) {
      width: 50%;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  &__texts {
    padding: 75px var(--excr-sct-horiz-margin);
    min-height: 270px;
    display: flex;
    flex-direction: column;
    gap: var(--excr-padding);
    justify-content: center;
    max-width: 800px;
    margin-inline: auto;

    @media screen and (min-width: 900px) {
      width: 50%;
    }
  }
}
