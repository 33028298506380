.results {
  display: flex;
  flex-direction: column;
  gap: var(--excr-gap);
  align-items: center;

  .titles {
    display: flex;
    flex-direction: column;
    gap: var(--excr-padding);
    text-align: center;
  }

  .tables {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--excr-margin);
    justify-content: center;
    width: 100%;

    @media screen and (min-width: 1600px) {
      max-width: var(--excr-max-large-container-width);
      margin-inline: auto;
    }
  }
}
