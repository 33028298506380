.btn {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  width: fit-content;

  border: solid 2px;
  border-color: var(--excr-primary);

  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;

  background: var(--excr-primary);
  color: var(--excr-white) !important;

  border-radius: 10px;

  box-shadow: var(--excr-shadow-hover);

  &:hover {
    cursor: pointer;
    border-color: var(--excr-primary);
    background: var(--excr-white);
    color: var(--excr-primary) !important;
    transition: all 0.3s ease-in-out;
    transform: translateY(-1px);
    box-shadow: var(--excr-shadow-hover);
  }

  &:active {
    border: solid 2px var(--excr-white);
    background: var(--excr-primary);
    box-shadow: var(--excr-shadow-clicked);
    color: var(--excr-white);
    transition: all 0s;
  }

  // Types

  &Wgc {
    border-color: var(--excr-wgc-color);
    color: var(--excr-white);
    background: var(--excr-wgc-color);

    &:hover {
      background: var(--excr-white);
      color: var(--excr-wgc-color);

      border-color: var(--excr-wgc-color);
    }

    &:active {
      border-color: var(--excr-white);
      background: var(--excr-white);
      color: var(--excr-wgc-color);
    }
  }

  &.white {
    background: var(--excr-white);
    color: var(--excr-primary) !important;

    &:hover {
      border-color: var(--excr-primary);
    }
    &:active {
      border-color: var(--excr-white);
      color: var(--excr-white);
      background: var(--excr-primary);
    }
  }
}
