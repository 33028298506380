@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.landing-page-body {
    /* Colors */
    --color-black : #1D1D1B;
    --color-grey : #797979;
    --color-light-grey : #C4C4C4;
    --color-white : #ffffff;

    --color-primary : #488f4b;
    --color-secondary : #aec540;
    --color-light : #fafee6;
    --color-bg : #f6f6f1;

    --rgb-primary : 72, 143, 75;

    --color-accent : #F9A620;

    /* Fonts */
    
    --h1-size : 32px;
    --h2-size : 28px;
    --h3-size : 25px;
    --h4-size : 21px;    
    --p-size : 16px;
    --small-size : 14px;

    /* Overwriting Bootstrap variables */
    --bs-gutter-x: 20px;
}
/* Fredrik Added 221108 */
.language-select {
    width: 40%;
    margin-top: 1rem;
    margin-left: 1rem;
    height:40px;
}
@media (min-width: 768px) {
    .language-select {
        width: 20%;
        margin-top: 1rem;
        margin-left: 1rem;
    }
}
@media (min-width: 992px) {
    .language-select {
        width: 10%;
        margin-top: 1rem;
        margin-left: 1rem;
    }
}
/* End Fredrik Added */

@media (min-width: 992px) { 
    .landing-page-body {
        --h1-size : 54px;
        --h2-size : 45px;
        --h3-size : 32px;
        --h4-size : 24px;
        --p-size : 18px;
        --small-size : 16px; 
    }
}


.landing-page-body {
    background-color : var(--color-white);
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: var(--p-size);
    color: var(--color-black);
}

p.on-image {
    font-weight: 500;
}

/* fonts */
h1.landing, h2.landing, h3.landing, h4.landing {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 900;
}

h1.landing { font-size: var(--h1-size);}

h2.landing {
    font-size: var(--h2-size);
    margin-bottom: 1.2rem;
}

h3.landing { font-size: var(--h3-size); }

h4.landing { font-size: var(--h4-size); }

small { font-size: var(--small-size);}

a.landing {
    color: var(--color-primary);
    text-decoration: none;
    transition: color .3s ease-in-out;
}

a.landing:hover {
    color: var(--color-grey);
    transition: color .3s ease-in-out;
}

.subtitle, .surtitle {
    color: var(--color-grey);
}

.subtitle {
    max-width: 960px;
}

.color-danger {
    color: #cf0000!important;
}

.text-shadow {
    text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.25);
}



/* .fonts */

/* --- buttons --- */
.btn {
    text-transform: uppercase;
    letter-spacing: 0.08em;
    
    border: none;
    border-radius: 10px;

    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.25);

    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.08em;

    padding: 10px 10px;

    transition: all .3 ease-in-out;

    margin-top: 5px;
    margin-bottom: 5px;

    border: solid 2px transparent;
}

.btn:hover {
    transition: all .3 ease-in-out;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.25);
}

.btn-primary {
    color: var(--color-white);
    background: linear-gradient(92.82deg, #488F4B -3.61%, #7EBB5D 29.58%, #8FC863 75.71%, #488F4B 104.4%);
    background-color: var(--color-primary);
    transition: all .3 ease-in-out;
    margin-right:4px;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: var(--color-white);
    background-image: none;
    color: var(--color-primary);
    border: solid 2px var(--color-primary);
    transition: all .3 ease-in-out;
}

.btn-primary:focus {
    box-shadow: 0 0 0 .25rem rgba(var(--rgb-primary),.5);
}

.btn-primary:active {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.btn-secondary {
    background-color : var(--color-white);

    /*
    background: linear-gradient(92.82deg, #488F4B -3.61%, #7EBB5D 29.58%, #8FC863 75.71%, #488F4B 104.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    */
    color: var(--color-primary);
    transition: all .3 ease-in-out;
}

.btn-secondary:hover,
.btn-secondary:focus {
    background-color: var(--color-secondary);
    border-color: var(--color-white);
    transition: all .3 ease-in-out;
}

.btn-outline {
    border: solid 2px var(--color-primary);
    color: var(--color-primary);
    transition: all .3 ease-in-out;
}

.btn-outline:hover,
.btn-outline:focus {
    border: solid 2px var(--color-white);
    color: var(--color-white);
    background-color: var(--color-secondary);
    transition: all .3 ease-in-out;
}



.btn-m-100 {
    width: 100%;
}

@media (min-width: 576px) {
    .btn-m-100 {
        width: auto;
    }
}

.btn-100 {
    width: 100%!important;
}

/* --- .buttons --- */

/* --- components --- */
nav.landing {
    background-color: var(--color-white);
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.25);
}

nav.landing img {
    height: 50px;
}

nav.landing #navbarText {
    align-content: end;
}

nav.landing ul {
    margin-left: auto;
}

nav.landing .nav-item {
    margin-right: 18px;
}

nav.landing .nav-btns .btn {
    padding: 6px 20px;
    box-shadow: none;
}

nav.landing .nav-link {
    color: var(--color-black);
    font-weight: 400;
}

nav.landing .nav-link i {
    margin-right: 8px;
}

nav.landing .nav-link:hover {
    color: var(--color-primary);
}

#temp-events {
    margin-top : -80px;
}

.event {
    height: 120px;
    width: 100%;

    display: flex;

    background-color: var(--color-white);
    
    margin: 20px 0;

    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.event:hover i {
    transition: all .3s ease-in-out;
    transform: translateX(10px);
    color: var(--color-primary);
}

.event > .img-container {
    height: 120px;
    width: 120px!important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
    display: block;
    flex-shrink: 0;
}

.event > .img-container > img {
    height: 100%;
    width: auto;
}

.event > .texts {
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.event > .texts > i {
    font-size: 22px;
    line-height: 20px;
    color: var(--color-primary);
    transition: all .3s ease-in-out;
}

.event h5 {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: .03em;
    line-height: 20px;
    color: var(--color-black);
}

@media (min-width: 992px) {
    #temp-events {
        margin-right: 20px;
    }
}

#concept-section .row > .col {
    margin-bottom: 20px;
}

.concept-item {
    background-color: var(--color-bg);
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    padding: 20px;
    height: 100%;
}

.concept-item > .icon {
    background-color : var(--color-primary);
    height: 40px;
    width: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    color: var(--color-white);
    font-size: 20px;
}

.concept-item > p {
    margin-bottom: 0px;
}

.numbered-items .item {
    margin-bottom: 1rem;
}

.numbered-items p:last-of-type, .numbered-items .item:last-of-type {
    margin-bottom: 0;
}

.numbered-items > .item h5 {
    color: var(--color-primary);
}

.numbered-items > .item > .item-title {
    display: flex;
}

.numbered-items > .item .item-number  {
    background-color: var(--color-primary);
    color: var(--color-light);
    height: 25px;
    width: 25px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-right: 10px;

    font-weight: 900;
    font-size: 18px;
}

.horiz-banner {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    background-color: var(--color-bg);
}

.horiz-banner > img {
    width: 100%;
    max-width: 1320px;
    margin: auto;
}

.two-one-thirds-section,
.one-two-thirds-section {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    min-height: 500px;
    margin: auto;
}

.two-one-thirds-section > .two-thirds,
.one-two-thirds-section > .two-thirds {
    padding-top: 100px;
    padding-bottom: 100px;
}

.two-one-thirds-section > .one-third,
.one-two-thirds-section > .one-third {
    max-height: 100vw;
    overflow: hidden;
}


.one-two-thirds-section {
    padding-top: 0px;
}

.two-one-thirds-section > .one-third {
    background-image: url(./images/landingpage/friends.JPG);
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;

    height: 100vw;
    display: block;
    padding: 20px;
}

.one-two-thirds-section > .one-third {
    background-image: url(./images/landingpage/how-to-play.jpg);
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vw;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    color: var(--color-white);
}

@media (min-width: 576px) {
    .two-one-thirds-section .one-third,
    .one-two-thirds-section .one-third {
        max-height: 60vw;
        max-width: 100%;
    }

    .two-one-thirds-section .one-third,
    .one-two-thirds-section .one-third {
        height: 60vw;
    }
}

@media (min-width: 992px) {
    .two-one-thirds-section,
    .one-two-thirds-section {
        flex-direction: row;
        padding-top: 0;
        padding-bottom: 0;
    }

    .one-two-thirds-section > .two-thirds,
    .two-one-thirds-section > .two-thirds {
        display: flex;
        align-items: center;
        padding-bottom: 0;
        padding-top: 0;
    }

    .two-one-thirds-section .one-third,
    .one-two-thirds-section .one-third {
        height: auto;
        max-width: 500px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .numbered-items {
        margin-top: 0;
        padding: 20px;
    }

    .one-third, .two-thirds {
        width: 100%;
    }

    .one-two-thirds-section .one-third > .container {
        max-width: 100%;
        margin-right: 0;
    }

    .one-two-thirds-section .two-thirds > .container {
        max-width: 640px;
        margin-left: 0;
    }

    .two-one-thirds-section .one-third > .container {
        max-width: 100%;
        margin-left: 0;
    }

    .two-one-thirds-section .two-thirds > .container {
        max-width: 640px;
        margin-right: 0;
    }
}

@media (min-width: 1200px) {
    .one-two-thirds-section .one-third > .container,
    .two-one-thirds-section .one-third > .container {
        max-width: 440px;
    }

    .one-two-thirds-section .two-thirds > .container,
    .two-one-thirds-section .two-thirds > .container {
        max-width: 760px;
    }
}

@media (min-width: 1400) {
    /*
    .one-two-thirds-section .one-third > .container,
    .two-one-thirds-section .one-third > .container {
        max-width: 440px;
    }
    */

    .one-two-thirds-section .two-thirds > .container,
    .two-one-thirds-section .two-thirds > .container {
        max-width: 880px;
    }
}

@media (min-width: 1600px) {
    .one-two-thirds-section,
    .two-one-thirds-section {
        max-width: 1400px;
        margin: auto;
    }
}

@media (min-width: 1800px) {

}

.category-card {
    background-color: var(--color-white);
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.25);

    color: var(--color-black);
    transition: all .3s ease-in-out;
}

.category-card:hover {
    color: var(--color-black);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.25);
    transform: translateY(-5px);
    transition: all .3s ease-in-out;
}

.cat-hcp-single > .bottom,
.cat-hcp-16 > .bottom,
.cat-hcp-54 > .bottom,
.cat-hcp-pro > .bottom {
    align-self: center;
    justify-self: baseline;
    background-size: cover;
    background-position : center;

}

.cat-hcp-single > .bottom,
.challenge-hcp-single > .top { background-image: url(./images/landingpage/hcp-single.JPG); }

.cat-hcp-16 > .bottom,
.challenge-hcp-16 > .top { background-image: url(./images/landingpage/hcp-16.JPG); }

.cat-hcp-54 > .bottom,
.challenge-hcp-54 > .top { background-image: url(./images/landingpage/hcp-54.JPG); }

.cat-hcp-pro > .bottom,
.challenge-hcp-pro > .top { background-image: url(./images/landingpage/hcp-pro.JPG); }

.category-card > .top {
    padding: 1.2rem;
}

.category-card > .bottom {
    height: 150px;
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    display: flex;
    justify-content: center;
    align-content: flex-end;
}


@media (min-width: 992px) {
    .category-card > .top {
        padding-top: 30px;
    }

    .category-card > .bottom {
        height: 300px;
    }

    .category-card > .bottom > img {
        height: 100%;
        width: auto;
    }

    #challenges-categories-section .btn {
        display: block;
        max-width: 300px;
        margin: auto;
    }
}

/* Challenge component */

.challenge-landing {
    background-color: var(--color-white);
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.challenge-landing > .top {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    background-position: center;
    background-size: cover;
    color: var(--color-white);

    display: grid;
    grid-template-rows: 2fr 1fr 1fr;
    grid-template-columns: 2fr 1fr;
}

.challenge-landing h4 {
    font-size: 24px;
    grid-row-start: 1;
    margin: 0;
}

.challenge-landing small {
    font-size: 16px;
}

.challenge-landing .badge {
    max-height: 24px;
    grid-row-start: 1;
}

.challenge-landing .date-period {
    grid-row-start: 2;
}

.challenge-landing .remaining-time {
    grid-row-start: 3;
    text-align: right;
}

.challenge-landing .game-type {
    grid-row-start: 3;
    grid-column-start: 1;
    align-self: end;
}


.challenge-landing > .top,
.challenge-landing > .bottom {
    padding: 20px;
}

.challenge-landing h4,
.challenge-landing span:not(.badge),
.challenge-landing small {
    text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.25);
}

.challenge-landing .badge {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.08em;
    font-family: 'Montserrat';
}

/* .Challenge component */

/* --- .components --- */

section.landing {
    padding-top: 100px;
    padding-bottom: 100px;
}

#intro-section {
    background-color: var(--color-primary);
    background-image: url(./images/landingpage/hero-section.jpg);
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
}

#intro-section .intro-gradient {
    background: rgb(59,115,62);
    background: -moz-linear-gradient(90deg, rgba(59,115,62,0.6) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(59,115,62,0.6) 0%, rgba(0,0,0,0) 100%);
    background: linear-gradient(90deg, rgba(59,115,62,0.6) 0%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3b733e",endColorstr="#000000",GradientType=1);
}

#intro-section .container {
    padding-top: 120px;
    padding-bottom: 180px;
}

#intro-section h1, #intro-section p {
    color: var(--color-white);
}

#intro-section h1 {
    max-width: 800px;
}

#intro-section p {
    max-width: 560px;
}

@media (min-width: 1200px) {
    #intro-section .container {
        padding-top: 170px;
        padding-bottom: 230px;
    }    
}

#concept-section {
    background-color: var(--color-white);
}

#how-to-section,
#private-challenges-section {
    padding: 0px;
    min-height: 500px;
    width: 100%;
}

#how-to-section {
    background-color: var(--color-light);
    margin: 0;
    width: 100%;
}

#challenges-categories-section {
    background-color: var(--color-bg);
}

#private-challenges-section {
    background-color: var(--color-white);
}

#cta {
    background-color: var(--color-bg);
    text-align: center;

    padding-top: 2rem;
    padding-bottom: 2rem;
}

#cta > .cta-background {
    background-image: url(./images/landingpage/CTA.JPG);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--color-white);
}

#cta .row > .col-12 {
    display: flex;
    align-items: center;
    justify-content: center;
}

#cta h3 {
    text-align: center;
    color: var(--color-white);
}

@media (min-width: 768px) {
    #cta {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    #cta h3 {
        text-align: left;
        margin-left: 20px;
    }
}


/* --- footer --- */
footer.landing {
    background-color: var(--color-bg);
    padding: 100px 0;
    text-align: center;
}

.footer-logo {
    width: 100%;
    text-align: center; 
}

.footer-logo > img {
    max-width: 150px;
    margin-bottom: 20px;
}

footer.landing .paypal-logo {
    width: 75px;
    height: auto;
}


footer.landing .stripe-logo { 
    width: 150px;
    height: auto;
    margin-left: 20px;
}


footer.landing hr {
    max-width: 200px;
    border: 0 none;
    height: 2px!important;
    background-image: linear-gradient(92.82deg, #488F4B -3.61%, #7EBB5D 29.58%, #8FC863 75.71%, #488F4B 104.4%);
    margin: 3rem auto;
}

.footer-top > .col {
    margin: 30px auto;
    text-align: left;
}

.footer-top > .col:last-of-type {
    text-align: center;
}

.footer-top > .col li {
    line-height: 40px;
}

.footer-top > .col li > a {
    width: 100%;
    display: block;
    color: var(--color-grey);
    transition: color .3s ease-in-out;
}

.footer-top > .col li > a:hover {
    color: var(--color-primary);
    transition: color .3s ease-in-out;
}

footer.landing ul {
    list-style-type: none;
    padding: 0; 
    margin: 0; 
}

.footer-socials {
    display: flex;
    font-size: 2rem;
    justify-content: center;
    margin: 30px auto;
    border-radius: 100%;
}

.footer-socials > li {
    margin: 0 5px; 
}

.footer-credits {
    text-align: center;
}

@media (min-width: 768px) {
    .footer-top > .col:last-of-type {
        text-align: left;
    }

    .footer-credits {
        display: flex;
        justify-content: center;
    }

    .footer-credits li::after {
        content: "|";
        margin-left: 10px;
        margin-right: 10px;
    }

    .footer-credits li:last-of-type::after {
        content: "";
        margin: 0;
    }
}

/* --- .footer --- */