.footer {
  background-color: var(--excr-primary);
  color: var(--excr-white);
  display: flex;
  padding: 20px var(--excr-sct-horiz-margin);
  padding-bottom: calc(20px + 50px);
  align-items: center;
  justify-content: center;
  text-align: center;

  @media screen and (min-width: 900px) {
    padding-bottom: 20px;
  }

  > a {
    color: var(--white) !important;
  }

  > a:hover {
    color: inherit;
    text-decoration: underline;
  }
}
