.menu {
  position: fixed;
  top: 0px;
  z-index: 10;
  width: 100%;
  transition: all 0.3s ease-in-out;
  background: var(--excr-white);
  box-shadow: var(--excr-shadow-hover);

  &__hidden {
    top: calc(var(--excr-navbar-height) * -1);
    transition: all 0.3s ease-in-out;
  }

  > .mainMenu {
    width: 100%;
    height: var(--excr-navbar-height);

    display: flex;

    padding: 0px var(--excr-sct-horiz-margin);

    border-bottom: 1px solid rgba(125, 125, 125, 0);
    transition: all 0.3s ease-in-out;

    > .mainMenu__container {
      width: 100%;
      max-width: var(--excr-max-large-container-width);
      margin: 0 auto;

      display: flex;
      flex-direction: row;

      align-items: center;
      justify-content: space-between;
    }
  }

  &:hover {
    > .mainMenu {
      background: #fff;
      transition: all 0.3s ease-in-out;
    }
  }

  &__links {
    height: 100%;
    display: none;
    flex-direction: row;
    align-items: center;
    gap: 30px;

    @media screen and (min-width: 1200px) {
      gap: var(--excr-gap);
    }

    font-size: var(--excr-fs-p);
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-weight: 700;

    > a {
      color: var(--excr-primary);

      display: flex;
      align-items: center;
      height: 100%;
      border-bottom: 2px solid transparent;

      &:hover {
        border-bottom: 2px solid var(--excr-black);
      }
    }
  }
}

.logo {
  display: flex;
  width: fit-content;
  height: 100%;
  max-height: 40px;

  @media screen and (min-width: 1200px) {
    max-height: 50px;
  }

  > img {
    height: 100%;
    width: auto;
  }
}

.menu__open {
  background: var(--excr-white);
  border-bottom: 1px solid rgba(125, 125, 125, 0.5);
}

@keyframes fadeIn {
  0% {
    // transform: translateY(-500px);
    opacity: 0;
  }

  100% {
    //transform: translateY(0px);
    opacity: 1;
  }
}

@media screen and (min-width: 900px) {
  .menu__links {
    display: flex;
  }
}
