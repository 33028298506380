.home {
  section {
    display: flex;
    flex-direction: column;
    gap: var(--excr-gap);

    &.ourChallenges,
    &.howtoplay {
      justify-content: center;
      text-align: center;
    }

    &.ourChallenges {
      background: var(--excr-grey);
      align-items: center;

      > .challenges {
        display: flex;
        flex-direction: column;
        gap: var(--excr-margin);
        align-items: center;
        justify-content: center;
        width: 100%;

        @media screen and (min-width: 900px) {
          flex-direction: row;
          flex-wrap: wrap;
        }

        @media screen and (min-width: 1600px) {
          max-width: var(--excr-max-large-container-width);
          margin-inline: auto;
        }
      }
    }

    &.howtoplay {
      align-items: center;
      max-width: var(--excr-max-container-width);
      margin-inline: auto;

      > .titles {
        max-width: 600px;
      }

      > ol {
        text-align: left;
        list-style: none;
        counter-reset: item;
        padding: 0;
        margin: 0;

        > li {
          counter-increment: item;
          margin-bottom: 5px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: var(--excr-padding);
          align-items: center;

          border-bottom: solid 2px var(--excr-primary);
          padding-block: 30px;
          margin: 0;

          @media screen and (min-width: 900px) {
            flex-wrap: nowrap;
          }

          &:before {
            margin-right: 10px;
            content: counter(item);
            background: var(--excr-primary);
            border-radius: 100%;
            color: var(--excr-white);
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            aspect-ratio: 1;
          }

          &:last-of-type {
            border: none;
          }

          > b {
            width: fit-content;
            @media screen and (min-width: 900px) {
              min-width: 210px;
            }
          }

          > span {
            width: 100%;
          }
        }
      }
    }

    > .titles {
      width: 100%;
      max-width: var(--excr-max-block-width);
      display: flex;
      flex-direction: column;
      gap: var(--excr-padding);
    }
  }
}
