/* Private Challenge */

/* ### General Settings ### */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
    /* Colors */
    --color-black: #1d1d1b;
    --color-grey: #797979;
    --color-light-grey: #c4c4c4;
    --color-white: #ffffff;
    --color-primary: #488f4b;
    --color-secondary: #aec540;
    --color-light: #fafee6;
    --color-bg: #f6f6f1;
}

body {
    background-color: var(--color-white);
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: var(--p-size);
    color: var(--color-black);
}

h1,
h2,
h3,
h4 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 900;
}


/* --- buttons --- */
.btn {
    text-transform: uppercase;
    letter-spacing: 0.08em;
    text-decoration: none;
    border: none;
    border-radius: 10px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.08em;
    padding: 10px 20px;
    transition: all 0.3 ease-in-out;
    margin-top: 5px;
    margin-bottom: 5px;
    border: solid 2px transparent;
}

.btn:hover {
    transition: all 0.3 ease-in-out;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.25);
}

.btn-primary {
    color: var(--color-white);
    background: linear-gradient( 92.82deg, #488f4b -3.61%, #7ebb5d 29.58%, #8fc863 75.71%, #488f4b 104.4% );
    background-color: var(--color-primary);
    transition: all 0.3 ease-in-out;
}

    .btn-primary:hover,
    .btn-primary:focus {
        background-color: var(--color-white);
        background-image: none;
        color: var(--color-primary);
        border: solid 2px var(--color-primary);
        transition: all 0.3 ease-in-out;
    }

    .btn-primary:focus {
        box-shadow: 0 0 0 0.25rem rgba(var(--rgb-primary), 0.5);
    }

    .btn-primary:active {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
    }

.btn-secondary {
    background-color: var(--color-white);
    /*
      background: linear-gradient(92.82deg, #488F4B -3.61%, #7EBB5D 29.58%, #8FC863 75.71%, #488F4B 104.4%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      */
    color: var(--color-primary);
    transition: all 0.3 ease-in-out;
}

    .btn-secondary:hover,
    .btn-secondary:focus {
        background-color: var(--color-secondary);
        border-color: var(--color-white);
        transition: all 0.3 ease-in-out;
    }

.btn-small {
    padding: 6px 8px;
}

/* --- .buttons --- */

/* ### Challenge component ### */
.private-challenge {
    max-width: 500px;
    background-color: var(--color-white);
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-bottom: 30px;
}

.private-challenge > .top {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-position: center;
    background-size: cover;
    color: var(--color-black);
    display: flex;
    flex-direction: column;
}

.private-challenge h4 {
    font-size: 24px;
    grid-row-start: 1;
    margin-bottom: 5px;
}

.private-challenge small {
    font-size: 16px;
}

.private-challenge .line {
    display: flex;
    justify-content: space-between;
}

    .private-challenge .line:last-of-type {
        margin-top: 15px;
    }

.private-challenge > .top,
.private-challenge > .bottom {
    padding: 20px;
}

.private-challenge > .middle {
    text-align: center;
    background-color: var(--color-secondary);
    padding: 5px 0;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.08em;
    font-family: "Montserrat";
}

.private-challenge .top h4,
.private-challenge .top span:not(.badge),
.private-challenge .top small {
    text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.25);
}

.private-challenge .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.private-challenge .players {
    font-size: 20px;
    margin-left: 10px;
}

    .private-challenge .players > i {
        font-size: 22px;
        margin-right: 5px;
    }

.private-challenge .price {
    height: fit-content;
    background: linear-gradient(131.53deg, #ffd700 6.6%, #ffb800 100.54%);
    border-radius: 10px;
    height: 50px;
    color: var(--color-black);
    font-family: "Montserrat";
    font-weight: 700;
    letter-spacing: 0.08em;
    font-size: 24px;
    padding: 10px 15px;
}

@media (min-width: 992px) {
    .private-challenge .line {
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
    }

    .private-challenge .bottom {
        flex-direction: column;
    }

        .private-challenge .bottom > .left-1 {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .private-challenge .bottom > .left-1,
        .private-challenge .bottom > .right-1 {
            width: 100%;
        }

    .private-challenge .btn {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .private-challenge .line {
        justify-content: space-between;
        flex-direction: row;
    }

    .private-challenge .bottom {
        flex-direction: row;
    }

        .private-challenge .bottom > .left-1 {
            margin-bottom: 0px;
            display: block;
        }
}

/* Private Challenge */

.private-challenge > .header {
    height: 130px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
    border-bottom: 1px solid var(--color-bg);
    position: relative;
}

    .private-challenge > .header > .btn-settings {
        position: absolute;
        right: 20px;
        top: 20px;
        width: fit-content;
        padding: 6px 8px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-size: 18px;
    }

    .private-challenge > .header > .logo {
        background: var(--color-white);
        width: 130px;
        background-size: contain;
    }

    .private-challenge > .header > .header-bg {
        width: 100%;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        background-size: contain;
    }

    .private-challenge > .header > .logo + .header-bg {
        /* If there's a logo, not rounded on the left */
        border-top-right-radius: 20px;
        border-top-left-radius: 0px;
    }

    .private-challenge > .header > .logo,
    .private-challenge > .header > .header-bg {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

.private-challenge .line.challenge-url {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.private-challenge .challenge-url > input {
    display: flex;
    height: 32px;
    width: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: none;
    background-color: var(--color-bg);
    font-size: 12px;
    font-weight: 500;
    color: var(--color-black);
    padding: 6px 12px;
    cursor: not-allowed;
}

.private-challenge .challenge-url > .btn {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: fit-content;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
}

.private-challenge .challenge-url > input {
    margin-bottom: 0px;
}

/* .Private Challenge */
@media (min-width: 992px) {
    .private-challenge .line {
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
    }

    .private-challenge .bottom > .left-1 {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .private-challenge .bottom > .left-1,
    .private-challenge .bottom > .right-1 {
        width: 100%;
    }

    .private-challenge .btn {
        display: block;
        width: fit-content;
    }
}

@media (min-width: 1200px) {
    .private-challenge .line {
        justify-content: space-between;
        flex-direction: row;
    }

    .private-challenge .bottom {
        flex-direction: row;
    }

        .private-challenge .bottom > .left-1 {
            margin-bottom: 0px;
            display: block;
        }
}