#wgcTournament #intro-section {
  background-image: url("./images/tournament/hero_tournament.jpg");
}

#wgcTournament #intro-section h1,
#wgcTournament #intro-section h3,
#wgcTournament #intro-section p {
  color: var(--color-white);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.45);
  text-align: center;
}

#wgcTournament #intro-section h1 {
  font-size: 80px;
  max-width: 100%;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 900;
  }

#wgcTournament #intro-section p {
  max-width: 60%;
  margin-top: 40px;
}

@media (max-width: 768px) {
  #wgcTournament #intro-section p {
    max-width: 100%;
  }
}

#wgcTournament #intro-section h1 {
  font-size: 80px;
  max-width: 100%;
}

#wgcTournament #intro-section > .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1200px) {
  #wgcTournament #intro-section .container {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}

#wgcTournament #intro-section h3 {
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.2em;

  display: flex;
  flex-direction: row;
  align-items: center;
}

#wgcTournament #intro-section h3 > span {
  display: block;
  background-color: white;
  height: 5px;
  width: 150px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.45);
  margin: auto 30px;
}

#wgcTournament #intro-section p {
  font-size: var(--h4-size);
}

@media (max-width: 1024px) {
  #wgcTournament #intro-section h1 {
    font-size: var(--h1-size);
  }

  #wgcTournament #intro-section h3 {
    font-size: var(--h4-size);
  }

  #wgcTournament #intro-section h3 > span {
    height: 3px;
    width: 120px;
    margin: auto 20px;
  }

  #wgcTournament #intro-section p {
    font-size: var(--p-size);
  }
}

#wgcTournament #how-to,
#wgcTournament #sponsors {
  background-color: var(--color-white);
}

#wgcTournament #concept-section,
#wgcTournament #challenges,
#wgcTournament #partners {
  background-color: var(--color-bg);
}

#wgcTournament #concept-section {
  padding-top:100px;
  padding-bottom:100px;
  text-align: center;
}

.tournament-header-h2   {
  font-size: var(--h2-size);
  margin-bottom: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 900;
}

.tournament-header-h3   {
  font-size: var(--h3-size);
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 900;
}

.green-link {
  color: var(--color-primary);
  text-decoration: none;
}

.green-link:hover {
  color: var(--color-grey);
  transition: color 0.3s ease-in-out;
}

#wgcTournament .subtitle {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  max-width: 100%;
}

#wgcTournament #concept-section .subtitle {
  margin: 30px auto;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: var(--p-size);
  color: var(--color-grey);
}

#wgcTournament #concept-section .row {
  justify-content: center;
}

#wgcTournament #concept-section .row .concept-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-white);
}

#wgcTournament #concept-section .row .concept-item > p:first-of-type {
  margin-bottom: 15px;
}

#wgcTournament #challenges {
  padding-top:100px;
  padding-bottom:100px;
  text-align: center;
}

#wgcTournament #challenges .section-top {
  margin-bottom: 50px;
}

#wgcTournament #challenges > .five-challenges-buttons {
  z-index: 10;
}

#wgcTournament #challenges .five-challenges {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto 20px;
  flex-wrap: wrap;
}

#wgcTournament #challenges.user-not-logged > .five-challenges {
  filter: blur(14px);
  margin-top: -180px;
}

#wgcTournament #challenges > .five-challenges-buttons {
  display: none;
}

#wgcTournament #challenges.user-not-logged > .five-challenges-buttons {
  position: sticky;
  top: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 20px;
}

#wgcTournament #challenges > .five-challenges-buttons h3 {
  margin-bottom: 20px;
  max-width: 550px;
}

#wgcTournament #challenges .five-challenges > .challenge-tournament {
  width: calc(100% / 5 - 20px);
  min-width: 290px;
  max-width: 350px;
  margin: 10px;
  background-color: var(--color-white);
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

#wgcTournament #challenges .five-challenges > .challenge-tournament > .top {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  text-align:left;
}

#wgcTournament #challenges .five-challenges > .challenge-tournament > .top > .line {
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}

#wgcTournament #challenges .five-challenges > .challenge-tournament > .top > .line > h4 {
  font-size: 24px;
  grid-row-start: 1;
  margin-bottom: 5px;
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.25);
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 900;
}


#wgcTournament #challenges .five-challenges > .challenge-tournament > .top {
    color: var(--color-white);
    background-image: url(./images/tournament/challenge-bg.jpg);
    padding: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
}

#wgcTournament #challenges .five-challenges > .challenge-tournament > .middle {
  text-align: center;
  background-color: var(--color-secondary);
  padding: 5px 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.08em;
  font-family: "Montserrat";
}

#wgcTournament #challenges .five-challenges > .challenge-tournament > .bottom {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

#wgcTournament #challenges .five-challenges > .challenge-tournament > .bottom > .left-tournament {
  width: 75px;
  margin-right: 20px;
}


#wgcTournament #challenges .five-challenges > .challenge-tournament > .bottom > .right-tournament {
  width:100%;
}

#wgcTournament #challenges .five-challenges > .challenge-tournament > .bottom > .right-tournament .btn {
  text-transform: uppercase;
  letter-spacing: 0.08em;
  border: none;
  border-radius: 10px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.08em;
  padding: 10px 20px;
  transition: all 0.3 ease-in-out;
  margin-top: 5px;
  margin-bottom: 5px;
  border: solid 2px transparent;

}



#wgcTournament #challenges .five-challenges > .challenge-tournament > .bottom > .right-tournament .btn:hover{
  background-color: var(--color-white);
  background-image: none;
  color: var(--color-primary);
  border: solid 2px var(--color-primary);
  transition: all 0.3 ease-in-out;
}


#wgcTournament #challenges .five-challenges > .challenge-tournament > .bottom > .right-tournament .btn-primary{
  width:100%;
  color: var(--color-white);
  background: linear-gradient( 92.82deg, #488f4b -3.61%, #7ebb5d 29.58%, #8fc863 75.71%, #488f4b 104.4% );
  background-color: rgba(0, 0, 0, 0);
  background-color: var(--color-primary);
}

#wgcTournament #challenges .five-challenges > .challenge-tournament > .bottom > .left {
  width: 75px;
  margin-right: 20px;
}

#wgcTournament
  #challenges
  .five-challenges
  > .challenge
  > .bottom
  > .left
  > .players {
  margin: 0;
}

@media (max-width: 1024px) {
  #wgcTournament #challenges .five-challenges > .challenge-tournament{
    width: 100%;
    min-width: 250px;
  }
}

@media (max-width: 768px) {
  #wgcTournament #challenges .five-challenges > .challenge-tournament{
    min-width: 250px;
  }
}

@media (max-width: 425px) {
  #wgcTournament #challenges .five-challenges > .challenge-tournament{
    min-width: 250px;
  }
}

#wgcTournament #challenges .challenge-card > .card-label {
  right: -10px;
  top: -10px;
  position: relative;
  float: right;
  padding: 15px;

  background-color: var(--color-primary);
  color: var(--color-white);
  border-radius: 10px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: var(--p-size);
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

#wgcTournament #challenges .challenge-card > .card-top {
    background: url(./images/tournament/card-bg.jpg);
    background-size: cover;
    background-position: center;
    color: var(--color-white);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: left;
    padding: 10px;
    padding-top: 65px;
}

#wgcTournament #challenges .challenge-card > .card-top h4,
#wgcTournament #challenges .challenge-card > .card-top p,
#wgcTournament
  #challenges
  .challenge-card
  > .card-top
  span
  #wgcTournament
  #challenges
  .challenge-card
  > .card-top
  a {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#wgcTournament #challenges .challenge-card > .card-top p {
  font-size: var(--small-size);
  margin-bottom: 20px;
}

#wgcTournament #challenges .challenge-card > .card-top > .tag-cta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#wgcTournament #challenges .challenge-card > .card-top span {
  background: linear-gradient(131.53deg, #ffd700 6.6%, #ffb800 100.54%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: var(--h4-size);
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;

  color: var(--color-black);
}

#wgcTournament #challenges .challenge-card > .card-top a {
  color: var(--color-white);
  text-decoration: underline;
}

#wgcTournament #challenges .challenge-card > .card-bottom {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#wgcTournament #challenges .challenge-card > .card-bottom > i {
  margin-right: 10px;
}

#wgcTournament #how-to {
  padding-top: 100px;
  padding-bottom: 100px;
}

#wgcTournament #how-to .section-top {
  margin-bottom: 50px;
  text-align: center;
}

@media (max-width: 425px) {
  #wgcTournament #how-to .section-top {
    text-align: left;
    margin-bottom: 30px;
  }
}

#wgcTournament #how-to .section-bottom .row {
  padding: 20px 0px;
  border-bottom: 2px solid var(--color-primary);
}

#wgcTournament #how-to .section-bottom .row:last-of-type {
  border-bottom: none;
}

#wgcTournament #how-to .section-bottom .row > .col-lg-3 {
  font-weight: 700;
  display: flex;
  flex-direction: row;
}

@media (max-width: 425px) {
  #wgcTournament #how-to .section-bottom .row > .col-lg-3 {
    margin-bottom: 20px;
  }
}

#wgcTournament #how-to .section-bottom .row > .col-lg-3 > span {
  background-color: var(--color-primary);
  color: var(--color-white);
  display: flex;
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin-right: 20px;
}

#wgcTournament #sponsors {
  text-align: center;
  padding-top:100px;
  padding-bottom: 0px;
}

#wgcTournament #sponsors h2 {
  margin-bottom: 20px;
}

#wgcTournament #sponsors hr {
  margin: 0 auto;
  height: 3px;
  background: linear-gradient(
    92.82deg,
    #488f4b -3.61%,
    #7ebb5d 29.58%,
    #8fc863 75.71%,
    #488f4b 104.4%
  );
  background-color: var(--color-primary);
  opacity: 1;
  max-width: 800px;
}

#wgcTournament #sponsors > .container > .row {
  padding-bottom: 100px;
  align-items: center;
  justify-content: center;
}

#wgcTournament #sponsors > .container > .row > .sponsor {
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: 200px;
}

#wgcTournament #sponsors > .container > .row > .sponsor:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.05);
}

#wgcTournament #sponsors > .container > .row > .sponsor > img {
  height: auto;
  width: 100%;
}

#wgcTournament #partners {
  padding-top: 100px;
  padding-bottom: 100px;
}

#wgcTournament #partners .partners-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 425px) {
  #wgcTournament #partners .partners-list {
    justify-content: space-around;
  }
}

#wgcTournament #partners .partners-list > a {
  display: flex;
  width: calc(100% / 8 - 20px);
  max-height: 120px;
  padding: 20px 10px;
  justify-content: center;
}

@media (max-width: 1024px) {
  #wgcTournament #partners .partners-list > a {
    width: 100px;
    height: 100px;
  }
}

#wgcTournament #partners .partners-list > a > img {
  width: auto;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

#wgcTournament #partners .partners-list > a > img:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

#wgcTournament
#private-challenges-section
.two-one-thirds-section > .one-third {
    background-image: url(./images/tournament/there-is-more-bg.jpg);
}
